import React from 'react'

const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Why Choose </span>us for</span> NFT Lending Platform Development?</h3>
            <p className="pharagraph head">Our NFT Lending Platform development service is the finest and top-notch service that you find in the crypto industry. We offer a single package in which you can find all that you need for developing an outstanding NFT Lending Platform platform.
            </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Efficient code.svg" alt="Efficient code" />
                </div>
                <h3>
                  Efficient Code
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  Our efficient coding practices allow high-speed transactions that enable your users to get instant and long-term loans using NFTs as collateral.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Inviolable security.svg" alt="Inviolable security" />
                </div>
                <h3>
                  Inviolable Security
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  We will embed all available protocols of security to provide an uncompromising environment to your NFT lending platform users.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Whitelabel customization.svg" alt="Whitelabel customization" />
                </div>
                <h3>
                  Whitelabel Customization
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  Fully customizable scripts and code libraries. The entire program can be altered later. The software completely belongs to you.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Ongoing support.svg" alt="Ongoing support" />
                </div>
                <h3>
                  Ongoing support
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                Even when your NFT lending platform is fully functional, we can provide you with ongoing support in case there are some snacks, troubleshooting requirements, or you want to add new features or modify existing features.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Scalable lending platform development.svg" alt="Scalable lending platform development" />
                </div>
                <h3>
                  Scalable lending platform development
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                Whatever your budget, whatever your company size, as an experienced NFT lending platform development company, we can easily scale our services.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/Custom coins.svg" alt="Custom coins" />
                </div>
                <h3>
                  Custom Coins
                </h3>
              </div>
              <div className="content-rght">
                
                <p className="pharagraph">
                  We can incorporate additional modules that will allow you to create custom coins for better liquidity options.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChoose