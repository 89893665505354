import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    return (
      <section className="whyshould">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">Why should you start</span>
                NFT Lending Platform Development?
              </h2>
              <p className="pharagraph head">Starting an NFT Lending Platform Development Service business in the crypto ecosystem will make you rich and earn an ample amount of profits in a short period. Here we share a few notable reasons to develop an NFT Lending Platform Development.
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/why-should-you-start-nft-lending-platform-development.svg" alt="Why Should Start nft lendgin platform Development" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph"  >In many cases the NFTs are just lying in the user’s wallet. They are unused.</li>
                <li className="pharagraph" >Unless they are traded, bought or sold, or auctioned, they solve no purpose.
                </li>
                <li className="pharagraph" >These unused tokens can be utilised for lending and borrowing purposes.</li>
                <li className="pharagraph" >They can lend their tokens and borrow funds. </li>
                <li className="pharagraph" >When funds are exchanged, as a platform owner, you get a small portion. </li>
                <li className="pharagraph" >This is a win-win situation for everyone. Unused non-fungible tokens can be used as collateral while you make money off every transaction. </li>
                <li className="pharagraph" >The NFT lending platform can be created as a standalone entity as well as it can be embedded within an existing NFT trading platform. </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould