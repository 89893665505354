import React from 'react'
import ButtonStratfreetrail from '../ButtonComponent/ButtonStratfreetrail'


const BannerSection =()=> {


    return (
      <section className="banner">
        <div className="container text-center">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h1 className="main-heading">NFT Lending Platform Development</h1>
            <p className="sub-heading">Allow your platform users to use NFTs as collateral.</p>
            <p className="sub-small">The NFTs are the most reliable and valuable digital assets that can be used for lending and borrowing money through a platform. Decentralized NFT lending and borrowing platform.</p>
          </div>
          <div className="quick text-center d-none d-md-block">
            <img width="983px" height="579px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/nft-lending-platform-development.png" alt="NFT Lending Platform development" />
          </div>
          <p className="pharagraph">The NFT lending platform can be used to represent non-fungible  tokens as collateral. We can develop you the perfect NFT lending platform.
          </p>
          <div className="text-center mt-4">
            <ButtonStratfreetrail />
          </div>
        </div>
      </section>
    )

}

export default BannerSection