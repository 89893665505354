import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/nftlending/Banner"
import Whatis from "../components/nftlending/Whatis"
import WhyShould from "../components/nftlending/WhyShould"
import TopFeatures from "../components/nftlending/TopFeatures"
import HowOur from "../components/nftlending/HowOur"
import CoreFeatures from "../components/nftlending/CoreFeatures"
import SecurityofOur from "../components/nftlending/SecurityofOur"
import UsecaseOf from "../components/nftlending/UsecaseOf"
import WhyChoose from "../components/nftlending/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import Review from "../components/Landing/Review"
import DevApproach from "../components/nftlending/DevApproach"
import TechStack from "../components/nftlending/TechStack"
import FaqSection from "../components/nftlending/FaqSection"
import PortfolioSection from "../components/Landing/Portfolio"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"

// import Header from "../components/header"




const nftlending = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>NFT Lending Platform Development | Coinsclone</title>
        <meta name="description" content="Build a secure & decentralized non-fungible token lending platform with elite features by choosing our exemplary NFT lending platform development services." />
        <meta name="keywords" content="NFT Lending Platform Development Services, NFT Lending and Borrowing Platform Development, Launch your NFT Loan Platform, Crypto Loan Platform Development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="NFT Lending Platform Development | Coinsclone" />
        <meta property="og:description" content="Build a secure & decentralized non-fungible token lending platform with elite features by choosing our exemplary NFT lending platform development services." />
        <meta property="og:url" content="https://www.coinsclone.com/nft-lending-platform-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/nft-lending-platform-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Build a secure & decentralized non-fungible token lending platform with elite features by choosing our exemplary NFT lending platform development services." />
        <meta name="twitter:title" content="NFT Lending Platform Development | Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/nft-lending-platform-development.png" />
        <link rel="canonical" href="https://www.coinsclone.com/nft-lending-platform-development/" />
      </Helmet>
      {/* <Header /> */}
      <BannerSection />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | NFT Lending Platform Development</span>
        </div>
      </div>
      <Whatis />
      <WhyShould />
      <TopFeatures />
      <HowOur />
      <CoreFeatures />
      <SecurityofOur />
      <UsecaseOf />
      <WhyChoose />
      <Testimonial />
      <Review />
      <PortfolioSection />
      <DevApproach />
      <TechStack />
      <FaqSection />
      <UserFriendly />
      
    </Layout>
  </section>
)

export default nftlending
