import React from 'react'




class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3">What is</span>
                NFT Lending Platform Development?</h2>
            </div> 
          </div>
          <div className="row table-content orderflex">
            <div className="col-lg-8 col-md-12 left-side order2">
              <p className="pharagraph" >An NFT lending platform is also called the NFT debt market. The platform users who already have NFTs can immediately gain liquidity by offering their NFTs as collateral. They can receive the loan, use it, and then later, they can pay it back and retrieve their NFTs.
              </p>
              <p className="pharagraph" >The conditions of the loan are embedded within the NFT. The lenders can use the historical value and additional context information of the NFTs to decide how much loan to give and how much liquidity to get back when the period of the loan is over.
              </p>
            </div>
            <div className="col-lg-4 col-md-12 right-side order1" >
              <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/nftlending/what-is-nft-lending-platform-development.svg" alt="What is nft exchange Development" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis