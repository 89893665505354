import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq">

      <Container>
        <div className="text-center">
          <h4 className="heading-h2"><span className="heading-h3 d-none d-md-block"><span className="bluecolor">FAQ</span>  </span>
            Frequently Asked Questions
          </h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                  What does an NFT lending platform do?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Up till now, arranging quick cash through loans has been a big problem because banks and financial institutions are involved. Even if the loan is taken from individuals, it can be a risky business for both parties. This problem is instantly solved through non-fungible tokens because their contracts are inviolable. Hence, NFT lending platforms are mushrooming everywhere. They have made it quite easy for people to use their NFTs as collateral to raise liquidity.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                  Why is using NFTs as collateral a better option for raising loans?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Non-fungible tokens are, as a matter of fact, small programs that are built on blockchains like Ethereum and Bitcoin. The contracts needed to take loans are hardcoded into the smart contracts. They are automatically executed. Hence, there can be no violation. This way, both the parties remain safe.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                  How can people lend cash to each other using the NFT lending platform?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">This happens through the peer-to-peer lending operations that we program into your lending platform. People who already own NFTs can get access to precious liquidity and at the same time enjoy the ownership of the digital assets.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                  How do I make money if I own an NFT lending platform?
                </Accordion.Toggle>

              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Liquidity mining is integrated within the platform. This enables you to earn high rewards when loans and NFTs are exchanged as collateral. These may be small amounts but with thousands of exchanges happening on daily basis, this can lead to unlimited revenue for you.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                  Why is community building important in an NFT lending platform?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">The more people use their NFTs as collateral to get loan, the more revenue your platform generates. For this to happen, people need to trust each other. This happens when you build a community. Consequently, most of the NFT lending platforms come with a robust community building capability.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle" variant="link" eventKey="5">
                  Why should I choose Coinsclone for my NFT lending platform development needs?
                </Accordion.Toggle>

              </div>
              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">For many years we have been building the very technologies that are needed to deploy high-performance NFT platforms. We have worked on multiple blockchain applications for small, medium-sized and large enterprises. We have developed our own custom scripts. Our team has an aggregated experience of 45 years. We use cutting-edge blockchain technologies and techniques to build advanced NFT lending platforms.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection