import React from 'react'


const SecurityofOur = () => {

  return (
    <section className="security">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12  text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Security Features</span> of our</span>
              NFT Lending Platform Development</h3>
            <p className="pharagraph head">Coinsclone always gives priority to security features. We incorporate robust security features to deliver a highly secure NFT Lending Platform Development platform.
            </p>
          </div>
        </div>
        <div className="binanceflexbox mt-0 mw1030" >
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="24.695" height="27.19" viewBox="0 0 24.695 27.19">
                  <g id="Fully_secure_non-fungible_tokens" data-name="Fully secure non-fungible tokens" transform="translate(-0.001)">
                    <path id="Path_22966" data-name="Path 22966" d="M6.052,0H18.639c.02.015.039.039.061.044a2.167,2.167,0,0,1,1.636,1.472A4.7,4.7,0,0,0,23.222,4.4a2.152,2.152,0,0,1,1.471,2.114q0,1.487,0,2.973a17.472,17.472,0,0,1-.538,4.466A18.756,18.756,0,0,1,12.69,27.19H12c-.4-.163-.806-.3-1.192-.493a18.9,18.9,0,0,1-8.493-8.109,18.224,18.224,0,0,1-2.263-7.91C-.018,9.275.005,7.866,0,6.459A2.093,2.093,0,0,1,1.4,4.432,4.836,4.836,0,0,0,4.406,1.394,2.072,2.072,0,0,1,5.561.178c.158-.071.327-.12.49-.178M9.019,10.31c-.113.015-.191.027-.269.036a1.866,1.866,0,0,0-1.693,1.808c-.02,1.637-.036,3.275.006,4.911a3.512,3.512,0,0,0,3.374,3.327q1.911.038,3.823,0a3.51,3.51,0,0,0,3.366-3.306c.045-1.645.029-3.292.007-4.937a1.862,1.862,0,0,0-1.7-1.8c-.084-.009-.168-.024-.265-.038,0-.8.013-1.583,0-2.361a3.325,3.325,0,0,0-6.648.113c-.006.743,0,1.486,0,2.251" transform="translate(0.001)" fill="gray" />
                    <path id="Path_22967" data-name="Path 22967" d="M162.654,227.145c0-.752,0-1.5,0-2.256,0-.366.089-.458.455-.459q3.252,0,6.5,0c.363,0,.452.094.453.461,0,1.46.007,2.92,0,4.38a1.958,1.958,0,0,1-2.06,2.045q-1.646,0-3.292,0a1.962,1.962,0,0,1-2.058-2.047c-.006-.708,0-1.416,0-2.124m2.912.713c0,.256-.009.512,0,.768a.794.794,0,0,0,.788.8.786.786,0,0,0,.8-.79q.024-.754,0-1.509a.793.793,0,0,0-.787-.8.8.8,0,0,0-.8.818c-.009.238,0,.477,0,.715" transform="translate(-154.014 -212.51)" fill="gray" />
                    <path id="Path_22968" data-name="Path 22968" d="M203.246,123.032H199.8c-.006-.079-.017-.147-.017-.216,0-.7-.01-1.4,0-2.1a1.729,1.729,0,0,1,3.456,0c.016.767,0,1.534,0,2.316" transform="translate(-189.169 -112.722)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Fully Secure Non-Fungible Tokens</h5> </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="21.287" height="29.591" viewBox="0 0 21.287 29.591">
                  <g id="Multilevel_SSL_security" data-name="Multilevel SSL security" transform="translate(0)">
                    <path id="Path_22964" data-name="Path 22964" d="M0,181.1q0-6.689,0-13.379,0-1.6,0-3.208v-.326H21.272c.006.1.015.184.015.268q0,8.264,0,16.529c0,.038,0,.077,0,.115H18.507c-.439-.3-.873-.6-1.319-.881a.679.679,0,0,0-.349-.093q-6.194-.007-12.387,0a.487.487,0,0,0-.252.032c-.459.308-.91.628-1.363.945Zm2.184-6.7a2.568,2.568,0,0,0,3.2,2.5,2.6,2.6,0,0,0,.937-4.594c-.684-.532-1.388-1.038-2.075-1.565a.838.838,0,0,1-.289-1.006.864.864,0,0,1,1.652.148l.038.153h1.7a2.068,2.068,0,0,0,0-.216,2.5,2.5,0,0,0-1.591-2.165,2.467,2.467,0,0,0-2.72.459,2.586,2.586,0,0,0,.2,4.044c.673.515,1.358,1.014,2.03,1.529a.839.839,0,0,1,.294,1,.857.857,0,0,1-1.64-.108c-.018-.062-.041-.123-.06-.178Zm6.365,0c.021.176.031.337.061.493a2.6,2.6,0,1,0,4.071-2.617c-.676-.51-1.358-1.013-2.031-1.527a.836.836,0,0,1-.3-1,.861.861,0,0,1,1.643.091c.019.064.036.129.054.194h1.73c-.035-.221-.052-.422-.1-.615a2.6,2.6,0,1,0-4.105,2.686c.7.541,1.418,1.061,2.121,1.6a.834.834,0,0,1,.274.985.893.893,0,0,1-.805.561.877.877,0,0,1-.822-.613c-.027-.079-.047-.16-.069-.237Zm6.9,2.574h3.765v-1.734h-2.05v-7.879H15.449Z" transform="translate(0 -151.513)" fill="gray" />
                    <path id="Path_22965" data-name="Path 22965" d="M63.951,10.911H61.8V10.6c0-1.262,0-2.524,0-3.786A4.57,4.57,0,0,0,52.717,6a6.711,6.711,0,0,0-.058.892c-.006,1.329,0,2.657,0,4.012H50.514a.415.415,0,0,1-.027-.1c.007-1.532-.036-3.066.037-4.594a6.726,6.726,0,0,1,13.317-.729,7.086,7.086,0,0,1,.122,1.292c.016,1.3.006,2.6.005,3.9,0,.075-.011.15-.018.242" transform="translate(-46.583)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Multilevel SSL Security</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="End-to-end_encryption" data-name="End-to-end encryption" xmlns="http://www.w3.org/2000/svg" width="30.079" height="30.125" viewBox="0 0 30.079 30.125">
                  <path id="Path_22961" data-name="Path 22961" d="M0,154.68a6.193,6.193,0,0,1,.453-1.1A2.483,2.483,0,0,1,2.829,152.4a2.52,2.52,0,0,1,2.192,1.5.384.384,0,0,0,.413.259q3.422-.013,6.843,0a.333.333,0,0,0,.373-.226,2.561,2.561,0,0,1,1.324-1.314c.074-.034.176-.12.177-.185.014-.535.008-1.072.008-1.633H7.131V138.415h15.82v12.369H15.923c0,.568-.005,1.113.007,1.657,0,.058.094.136.161.167a2.7,2.7,0,0,1,1.38,1.38.341.341,0,0,0,.257.156c2.34.008,4.68,0,7.02.013.2,0,.235-.116.3-.248a2.643,2.643,0,1,1,2.966,3.7c-.075.017-.148.042-.222.063h-.764c-.023-.016-.045-.042-.07-.047A2.663,2.663,0,0,1,25,156.063a.379.379,0,0,0-.293-.143c-2.309-.008-4.618,0-6.928-.011a.317.317,0,0,0-.341.229,2.474,2.474,0,0,1-1.222,1.256c-.243.115-.506.19-.76.282h-.823c-.023-.016-.045-.042-.07-.047a2.663,2.663,0,0,1-1.96-1.566.379.379,0,0,0-.293-.143c-2.309-.008-4.618,0-6.928-.011a.317.317,0,0,0-.341.229,2.474,2.474,0,0,1-1.222,1.256c-.243.115-.506.19-.76.282H2.291c-.064-.02-.128-.043-.193-.058a2.632,2.632,0,0,1-1.972-1.785c-.051-.146-.084-.3-.126-.448v-.7m15.922-6.559c0-.558.012-1.1-.006-1.633a.378.378,0,0,1,.255-.415,2.539,2.539,0,0,0,1.505-2.249,2.641,2.641,0,1,0-3.734,2.265.317.317,0,0,1,.222.346c-.013.556,0,1.112,0,1.685Z" transform="translate(0 -127.551)" fill="gray" />
                  <path id="Path_22962" data-name="Path 22962" d="M94.866,9.073H90.48A7.905,7.905,0,0,1,93.664,1.63a7.6,7.6,0,0,1,8.567-.581,8.022,8.022,0,0,1,4.075,8.014h-4.391c0-.359,0-.718,0-1.076a3.524,3.524,0,0,0-7.047-.122c-.008.391,0,.783,0,1.207" transform="translate(-83.35 0)" fill="gray" />
                  <path id="Path_22963" data-name="Path 22963" d="M182.171,195.317a.88.88,0,1,1-.886-.867.89.89,0,0,1,.886.867" transform="translate(-166.251 -179.189)" fill="gray" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">End-To-End Encryption
                </h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Native_technology" data-name="Native technology" xmlns="http://www.w3.org/2000/svg" width="30.881" height="29.297" viewBox="0 0 30.881 29.297">
                  <path id="Path_22959" data-name="Path 22959" d="M0,17.419V5.718a1.757,1.757,0,0,0,.053-.2A6.306,6.306,0,0,1,6.442,0q9,0,18,0a6.571,6.571,0,0,1,1.051.072A6.3,6.3,0,0,1,30.878,6.4q.006,5.14,0,10.281a6.45,6.45,0,0,1-.082,1.11,6.3,6.3,0,0,1-6.341,5.3c-1.538,0-3.075,0-4.613-.005a.44.44,0,0,0-.447.255Q17.869,26,16.325,28.657a.932.932,0,0,1-1.768,0q-1.544-2.673-3.083-5.349a.4.4,0,0,0-.4-.233q-2.4.013-4.794,0A6.311,6.311,0,0,1,.169,18.206c-.061-.261-.113-.525-.169-.787m26.365-7.45c0-.318.011-.63,0-.94a3.323,3.323,0,0,0-6.58-.474,13.247,13.247,0,0,0-.1,1.431c-.69.219-.859.452-.859,1.182q0,2.5,0,5.006a.937.937,0,0,0,1.054,1.06h6.332a.934.934,0,0,0,1.052-1.059q0-2.5,0-5.006c0-.737-.156-.954-.9-1.2M9.6,7c1.687,0,3.374,0,5.061,0a.9.9,0,0,0,.95-.884.924.924,0,0,0-.922-.92c-.06,0-.12,0-.181,0q-4.88,0-9.761,0a2.03,2.03,0,0,0-.389.03A.892.892,0,0,0,4.24,6.951,1.632,1.632,0,0,0,4.715,7q2.44.005,4.88,0m-.88,5.461q-2.034,0-4.068,0a.918.918,0,0,0-1.019.906.93.93,0,0,0,1,.9q4.083.005,8.167,0a.908.908,0,1,0-.014-1.807q-2.034,0-4.068,0M7.5,8.835c-.975,0-1.95-.005-2.925,0a.9.9,0,1,0-.022,1.8c1.96.012,3.92.016,5.879-.009a1.2,1.2,0,0,0,.707-.325.775.775,0,0,0,.144-.923.848.848,0,0,0-.829-.543c-.985,0-1.97,0-2.955,0M6.332,16.09c-.582,0-1.164,0-1.745,0A.905.905,0,1,0,4.595,17.9q1.715.006,3.431,0a.905.905,0,1,0-.007-1.807c-.562,0-1.123,0-1.685,0" transform="translate(0 0.001)" fill="gray" />
                  <rect id="Rectangle_8836" data-name="Rectangle 8836" width="4.775" height="3.585" transform="translate(20.654 11.832)" fill="gray" />
                  <path id="Path_22960" data-name="Path 22960" d="M269.857,97.691h-3.063a7.508,7.508,0,0,1,.1-1.229,1.463,1.463,0,0,1,1.64-1.066,1.494,1.494,0,0,1,1.319,1.418c.014.28,0,.562,0,.877" transform="translate(-245.298 -87.699)" fill="gray" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Native Technology Integrated Security</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="binanceflexbox mw1030" >
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="24.696" height="29.226" viewBox="0 0 24.696 29.226">
                  <g id="KYC_integration" data-name="KYC integration" transform="translate(0)">
                    <path id="Path_22956" data-name="Path 22956" d="M22.053,13.916a4.84,4.84,0,1,0-6.112,7.479,7.488,7.488,0,0,0-3.02,2.295,7.364,7.364,0,0,0-1.5,3.452c-.1,0-.194.012-.284.012-2.07,0-4.141.006-6.211,0A4.853,4.853,0,0,1,.078,23.1,5.227,5.227,0,0,1,0,22.166Q0,13.567,0,4.967A4.853,4.853,0,0,1,4.972,0Q11.046,0,17.12,0a4.864,4.864,0,0,1,4.943,4.924q.006,4.368,0,8.736c0,.069-.007.138-.012.253M11.014,6.775H15.7c.183,0,.366.006.548,0a.943.943,0,0,0,.863-.7.876.876,0,0,0-.344-1.019,1.653,1.653,0,0,0-.8-.219c-3.287-.014-6.574-.01-9.86-.008a2.218,2.218,0,0,0-.453.041A.9.9,0,0,0,5,5.409a.964.964,0,0,0,.928,1.363c1.694.007,3.388,0,5.082,0m0,5.187h4.93a2.773,2.773,0,0,0,.395-.018.96.96,0,0,0,.772-1.223A1.014,1.014,0,0,0,16,10.014q-4.96,0-9.921,0a2.241,2.241,0,0,0-.363.025.952.952,0,0,0-.8.906A.976.976,0,0,0,5.993,11.96c1.674,0,3.347,0,5.021,0M8.234,15.189c-.761,0-1.522-.006-2.283,0a.972.972,0,1,0-.009,1.942c1.522.01,3.044.012,4.566-.006a1.282,1.282,0,0,0,.648-.206.907.907,0,0,0,.34-1.052.974.974,0,0,0-.947-.677c-.771-.01-1.542,0-2.313,0" fill="gray" />
                    <path id="Path_22957" data-name="Path 22957" d="M163.315,281.06h-4.808a1.012,1.012,0,0,1-1.124-1.182,5.946,5.946,0,0,1,11.858-.395c.013.162.033.323.036.485a.991.991,0,0,1-1.064,1.091q-2.449,0-4.9,0" transform="translate(-144.582 -251.834)" fill="gray" />
                    <path id="Path_22958" data-name="Path 22958" d="M195.924,176.557a3.355,3.355,0,1,1-3.354-3.348,3.364,3.364,0,0,1,3.354,3.348" transform="translate(-173.826 -159.123)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">KYC Integration</h5>
              </div> </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Secure_blockchains" data-name="Secure blockchains" xmlns="http://www.w3.org/2000/svg" width="31.17" height="29.227" viewBox="0 0 31.17 29.227">
                  <path id="Path_22953" data-name="Path 22953" d="M31.17,3.046A3.372,3.372,0,0,1,30.522,4.5a2.715,2.715,0,0,1-4.535-.573.389.389,0,0,0-.426-.275c-.547.02-1.1.007-1.643.007a.917.917,0,0,0-.806.409C22.3,5.266,21.5,6.473,20.675,7.71l2.509,1.15c-.11.063-.188.112-.271.153q-3.546,1.774-7.1,3.54a.533.533,0,0,1-.41.028q-3.635-1.8-7.259-3.623c-.044-.022-.085-.051-.162-.1l2.493-1.142c-.041-.073-.07-.134-.106-.19C9.631,6.414,8.886,5.3,8.15,4.186a1.075,1.075,0,0,0-.989-.534c-.547.013-1.1-.006-1.643.012a.392.392,0,0,0-.283.184,2.7,2.7,0,0,1-4.686.5A5.78,5.78,0,0,1,0,3.046V2.437a5.781,5.781,0,0,1,.549-1.3,2.7,2.7,0,0,1,4.686.5.392.392,0,0,0,.283.184c.527.018,1.055.018,1.582.007A2.846,2.846,0,0,1,9.7,3.221c.752,1.155,1.527,2.3,2.292,3.443.061.091.124.18.192.279.978-.447,1.937-.879,2.89-1.324a1.128,1.128,0,0,1,1.021,0c.862.4,1.73.8,2.6,1.19.1.045.2.081.3.123.075-.11.138-.2.2-.293l2.347-3.516a2.755,2.755,0,0,1,2.438-1.294c.527,0,1.055-.013,1.582.007a.389.389,0,0,0,.426-.275A2.715,2.715,0,0,1,30.522.986a3.371,3.371,0,0,1,.648,1.451Z" transform="translate(0 0)" fill="gray" />
                  <path id="Path_22954" data-name="Path 22954" d="M0,190.12a3.371,3.371,0,0,1,.648-1.451,2.715,2.715,0,0,1,4.535.574.389.389,0,0,0,.427.275c.547-.02,1.1-.007,1.643-.007a.917.917,0,0,0,.806-.41c.786-1.168,1.566-2.341,2.364-3.536-.657-.33-1.278-.707-1.944-.961a1.629,1.629,0,0,1-1.132-1.82c.058-2.616.02-5.234.02-7.852v-.365c.14.064.24.105.336.153q3.357,1.677,6.715,3.355c.148.074.262.132.261.347-.009,3.023-.006,6.046-.007,9.069,0,.048-.008.1-.016.183l-2.575-1.284c-.064.089-.129.174-.189.263-.732,1.1-1.473,2.186-2.191,3.291a2.845,2.845,0,0,1-2.6,1.4c-.527-.011-1.055-.011-1.582.007a.393.393,0,0,0-.283.184,2.7,2.7,0,0,1-4.686.5,5.781,5.781,0,0,1-.549-1.3v-.609" transform="translate(0 -163.939)" fill="gray" />
                  <path id="Path_22955" data-name="Path 22955" d="M285.636,190.815a3.371,3.371,0,0,1-.648,1.451,2.715,2.715,0,0,1-4.535-.574.389.389,0,0,0-.427-.274c-.527.02-1.055.005-1.582.007a2.755,2.755,0,0,1-2.437-1.295q-1.122-1.683-2.245-3.365c-.061-.092-.127-.181-.2-.289l-2.594,1.292v-.313q0-4.443,0-8.887c0-.172.015-.291.2-.383q3.444-1.707,6.879-3.434c.06-.03.125-.051.228-.093v.329c0,2.78-.007,5.559.006,8.339a1.137,1.137,0,0,1-.712,1.153c-.7.328-1.379.684-2.068,1.029-.089.045-.176.093-.279.147.795,1.19,1.575,2.363,2.361,3.531a.917.917,0,0,0,.806.41c.568,0,1.136-.007,1.7,0a.313.313,0,0,0,.337-.219,2.673,2.673,0,0,1,2.847-1.571,2.717,2.717,0,0,1,2.313,2.221,1.534,1.534,0,0,0,.051.173Z" transform="translate(-254.466 -164.025)" fill="gray" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Secure Blockchains</h5> </div>
            </div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg id="Secure_blockchains" data-name="Secure blockchains" xmlns="http://www.w3.org/2000/svg" width="31.17" height="29.227" viewBox="0 0 31.17 29.227">
                  <path id="Path_22953" data-name="Path 22953" d="M31.17,3.046A3.372,3.372,0,0,1,30.522,4.5a2.715,2.715,0,0,1-4.535-.573.389.389,0,0,0-.426-.275c-.547.02-1.1.007-1.643.007a.917.917,0,0,0-.806.409C22.3,5.266,21.5,6.473,20.675,7.71l2.509,1.15c-.11.063-.188.112-.271.153q-3.546,1.774-7.1,3.54a.533.533,0,0,1-.41.028q-3.635-1.8-7.259-3.623c-.044-.022-.085-.051-.162-.1l2.493-1.142c-.041-.073-.07-.134-.106-.19C9.631,6.414,8.886,5.3,8.15,4.186a1.075,1.075,0,0,0-.989-.534c-.547.013-1.1-.006-1.643.012a.392.392,0,0,0-.283.184,2.7,2.7,0,0,1-4.686.5A5.78,5.78,0,0,1,0,3.046V2.437a5.781,5.781,0,0,1,.549-1.3,2.7,2.7,0,0,1,4.686.5.392.392,0,0,0,.283.184c.527.018,1.055.018,1.582.007A2.846,2.846,0,0,1,9.7,3.221c.752,1.155,1.527,2.3,2.292,3.443.061.091.124.18.192.279.978-.447,1.937-.879,2.89-1.324a1.128,1.128,0,0,1,1.021,0c.862.4,1.73.8,2.6,1.19.1.045.2.081.3.123.075-.11.138-.2.2-.293l2.347-3.516a2.755,2.755,0,0,1,2.438-1.294c.527,0,1.055-.013,1.582.007a.389.389,0,0,0,.426-.275A2.715,2.715,0,0,1,30.522.986a3.371,3.371,0,0,1,.648,1.451Z" transform="translate(0 0)" fill="gray" />
                  <path id="Path_22954" data-name="Path 22954" d="M0,190.12a3.371,3.371,0,0,1,.648-1.451,2.715,2.715,0,0,1,4.535.574.389.389,0,0,0,.427.275c.547-.02,1.1-.007,1.643-.007a.917.917,0,0,0,.806-.41c.786-1.168,1.566-2.341,2.364-3.536-.657-.33-1.278-.707-1.944-.961a1.629,1.629,0,0,1-1.132-1.82c.058-2.616.02-5.234.02-7.852v-.365c.14.064.24.105.336.153q3.357,1.677,6.715,3.355c.148.074.262.132.261.347-.009,3.023-.006,6.046-.007,9.069,0,.048-.008.1-.016.183l-2.575-1.284c-.064.089-.129.174-.189.263-.732,1.1-1.473,2.186-2.191,3.291a2.845,2.845,0,0,1-2.6,1.4c-.527-.011-1.055-.011-1.582.007a.393.393,0,0,0-.283.184,2.7,2.7,0,0,1-4.686.5,5.781,5.781,0,0,1-.549-1.3v-.609" transform="translate(0 -163.939)" fill="gray" />
                  <path id="Path_22955" data-name="Path 22955" d="M285.636,190.815a3.371,3.371,0,0,1-.648,1.451,2.715,2.715,0,0,1-4.535-.574.389.389,0,0,0-.427-.274c-.527.02-1.055.005-1.582.007a2.755,2.755,0,0,1-2.437-1.295q-1.122-1.683-2.245-3.365c-.061-.092-.127-.181-.2-.289l-2.594,1.292v-.313q0-4.443,0-8.887c0-.172.015-.291.2-.383q3.444-1.707,6.879-3.434c.06-.03.125-.051.228-.093v.329c0,2.78-.007,5.559.006,8.339a1.137,1.137,0,0,1-.712,1.153c-.7.328-1.379.684-2.068,1.029-.089.045-.176.093-.279.147.795,1.19,1.575,2.363,2.361,3.531a.917.917,0,0,0,.806.41c.568,0,1.136-.007,1.7,0a.313.313,0,0,0,.337-.219,2.673,2.673,0,0,1,2.847-1.571,2.717,2.717,0,0,1,2.313,2.221,1.534,1.534,0,0,0,.051.173Z" transform="translate(-254.466 -164.025)" fill="gray" />
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">Smart Contracts</h5>
              </div></div>
          </div>
          <div className="binanceboxt card">
            <div className="binancecont">
              <div className="svgback">
                <svg xmlns="http://www.w3.org/2000/svg" width="33.028" height="33.058" viewBox="0 0 33.028 33.058">
                  <g id="World-class_protocols" data-name="World-class protocols" transform="translate(0 0)">
                    <path id="Path_22940" data-name="Path 22940" d="M16.516,23.6c-2.641,0-5.282.054-7.921-.015a8.627,8.627,0,0,1-7.113-3.7A7.87,7.87,0,0,1,3.746,8.61,9.109,9.109,0,0,1,7.5,7.189.308.308,0,0,0,7.8,6.9a8.339,8.339,0,0,1,6.9-6.7,8.98,8.98,0,0,1,9.5,4,7.346,7.346,0,0,1,1.009,2.628A.387.387,0,0,0,25.6,7.2a8.739,8.739,0,0,1,5.928,3.56,7.841,7.841,0,0,1-1.557,10.81A8.84,8.84,0,0,1,24.4,23.589c-2.628.033-5.256.008-7.884.008M11.8,13.557q0,2.579,0,5.157a1.2,1.2,0,0,0,1.333,1.348q3.96,0,7.92,0A1.2,1.2,0,0,0,22.411,18.7c0-2.628-.006-5.255.006-7.883a1.47,1.47,0,0,0-.56-1.217c-.838-.7-1.666-1.415-2.491-2.131a1.5,1.5,0,0,0-1.04-.385c-1.731.009-3.463,0-5.194,0A1.2,1.2,0,0,0,11.8,8.436q0,2.56,0,5.12" transform="translate(0 0)" fill="gray" />
                    <path id="Path_22941" data-name="Path 22941" d="M271.393,2.611c.168-.188.329-.384.506-.563.508-.516,1.017-1.032,1.537-1.535a1.174,1.174,0,1,1,1.669,1.652c-.6.618-1.212,1.218-1.819,1.827-.085.086-.166.176-.321.341.238,0,.39-.008.542,0a1.168,1.168,0,0,1,.107,2.324,5.524,5.524,0,0,1-.734.03q-1.344.011-2.688.015a1.229,1.229,0,0,1-1.282-1.233c0-1.08,0-2.16.02-3.24a1.172,1.172,0,0,1,1.469-1.2,1.154,1.154,0,0,1,.873,1.02c.016.167.02.334.03.5l.09.061" transform="translate(-242.432 -0.138)" fill="gray" />
                    <path id="Path_22942" data-name="Path 22942" d="M4.227,2.63c.012-.22.017-.375.029-.53a1.169,1.169,0,0,1,2.327-.069,4.655,4.655,0,0,1,.023.588q.011,1.418.017,2.835A1.218,1.218,0,0,1,5.41,6.716c-1.092.01-2.185.009-3.277-.018A1.175,1.175,0,0,1,.955,5.207a1.146,1.146,0,0,1,1-.85c.168-.016.338-.016.571-.026-.1-.117-.157-.2-.228-.271-.6-.6-1.2-1.2-1.8-1.8A1.183,1.183,0,1,1,2.176.606c.584.579,1.164,1.163,1.746,1.743.083.083.172.159.3.28" transform="translate(-0.069 -0.155)" fill="gray" />
                    <path id="Path_22943" data-name="Path 22943" d="M76.637,255.885c.178-.168.288-.28.407-.382a1.169,1.169,0,0,1,1.6.053,1.14,1.14,0,0,1,.053,1.6q-1.207,1.266-2.467,2.481a1.139,1.139,0,0,1-1.623-.059q-1.163-1.156-2.311-2.325a1.177,1.177,0,1,1,1.667-1.659c.079.076.143.168.272.322.021-.183.038-.269.039-.356,0-.565,0-1.13,0-1.694,0-1.632.307-2.2,1.188-2.191s1.17.568,1.17,2.21Z" transform="translate(-64.839 -226.893)" fill="gray" />
                    <path id="Path_22944" data-name="Path 22944" d="M194.011,255.888c0-1.07-.008-2.05,0-3.029a1.171,1.171,0,0,1,1.158-1.186,1.183,1.183,0,0,1,1.2,1.189c.006.221,0,.442,0,.663,0,.758,0,1.517,0,2.352.163-.151.272-.256.386-.357a1.172,1.172,0,0,1,1.63.043,1.142,1.142,0,0,1,.04,1.6q-1.209,1.264-2.468,2.48a1.122,1.122,0,0,1-1.568-.024q-1.224-1.2-2.415-2.429a1.152,1.152,0,0,1,.02-1.628,1.171,1.171,0,0,1,1.63-.039c.109.1.211.2.385.367" transform="translate(-172.778 -226.893)" fill="gray" />
                    <path id="Path_22945" data-name="Path 22945" d="M149.917,104.2H144.08V95.951h.9c.9,0,1.794-.008,2.69.007a.8.8,0,0,1,.459.162c.553.449,1.092.915,1.623,1.389a.563.563,0,0,1,.188.361c.011,2.064.007,4.127.006,6.191a.864.864,0,0,1-.027.137" transform="translate(-129.893 -86.502)" fill="gray" />
                  </g>
                </svg>
              </div>
              <div className="card-body">
                <h5 className="card-title">World-Class Protocols</h5>
              </div> </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SecurityofOur